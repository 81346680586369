.showPassIcon {
  position: absolute;
  width: 30px;
  z-index: 20;
  top: 16%;
  right: 24px;
  cursor: pointer
}

.back-form-button {
  cursor: pointer;
  margin-right: 20px;
  @media (max-width: 400px) {
    margin-right: 0;
  }

  i {
    margin-right: 10px;
    @media (max-width: 400px) {
      margin-right: 0;
    }
  }

  span {
    @media (max-width: 400px) {
      display: none;
    }
  }
}

.form-title {
  font-size: 20px;
}

.custom-control-label::before {
  top: 50%;
  transform: translateY(-50%);
}

.custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $app-color;
  background: $app-color;
}

.radio-border {
  border: 1px solid #dee2e6 ;
}

.red-border {
  border-color: $app-color;
}