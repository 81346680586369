.ReactTable {
  background: transparent;
  font-size: 10px;
  font-weight: 400;
  border: none !important;


  .rt-noData {
    padding: 4px 6px !important;
    background: rgba(25, 25, 25, 0.08) !important;
    color: #191919 !important;
  }

  .rt-thead.-header {
    color: #191919;
    font-size: 12px;
    font-family: Arial-400, sans-serif;
    box-shadow: none !important;
    border-top: 1px solid rgba(25, 25, 25, 0.24) !important;
    border-bottom: 1px solid rgba(25, 25, 25, 0.24) !important;
    //border-top: 1px solid rgba(25, 25, 25, 0.24) !important;
  }

  padding: 4px;

  .rt-tbody {
    .rt-tr-group {
      border-bottom: 1px solid rgba(25, 25, 25, 0.24) !important;

      .rt-td {
        overflow: hidden;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .rt-table {
    overflow-x: scroll !important;
  }

  .rt-td {
    height: 32px;
  }

  .rt-td {
    display: flex;
  }

  &::-webkit-scrollbar {
    width: 0px !important;
    height: 2px !important;
  }

  .rt-thead.-header {
    //border-top: none !important;
    .rt-thead {
      .rt-th {
        min-height: 40px;

        border-right: 1px solid rgba(25, 25, 25, 0.24) !important;
      }

      .rt-td {
        border-right: 1px solid rgba(25, 25, 25, 0.24) !important;
      }
    }
  }

  .rt-thead {
    //border-bottom: 1px solid var(--light-gray-bg) !important;

    .rt-td {
      border-right: 1px solid rgba(25, 25, 25, 0.24) !important;
    }

    .rt-resizable-header-content {
      white-space: initial !important;
    }
  }

  .rt-thead.-filters {
    border-bottom: 1px solid rgba(25, 25, 25, 0.24) !important;
    //border-top: none!important;
    padding: 2px 0;

    .rt-th {
      border-right: 1px solid rgba(25, 25, 25, 0.24) !important;

      &:last-child {
        border-right: none !important;
      }

    }

    input, select {
      padding: 2px 6px !important;
      background: #fff !important;
      border-color: rgba(25, 25, 25, 0.24) !important;
      color: #191919 !important;
      height: 22px !important;
    }
  }

  .rt-resizable-header-content {
    padding: 0 10px;
    text-align: center;
    line-height: 2;
  }

  .rt-th.-sort-asc {
    position: relative;
    box-shadow: none !important;

    &::before {
      font-size: 8px;
      content: '▲';
      position: absolute;
      right: 3px;
      top: 2%;
      color: #36f;
    }

    &::after {
      font-size: 8px;
      content: '▼';
      position: absolute;
      right: 3px;
      bottom: 2%;
      color: #1e3d96;
    }
  }

  .rt-th.-sort-desc {
    position: relative;
    box-shadow: none !important;

    &::before {
      font-size: 8px;
      content: '▲';
      position: absolute;
      right: 3px;
      top: 2%;
      color: #1e3d96;
    }

    &::after {
      font-size: 8px;
      content: '▼';
      position: absolute;
      right: 3px;
      bottom: 2%;
      color: #36f;
    }
  }

  .rt-th {
    font-weight: 600;
    //border-right: 1px solid rgba(25, 25, 25, 0.24) !important;
    border-right: 1px solid rgba(25, 25, 25, 0.24) !important;

    &:last-child {
      border-right: none !important;
    }
  }

  .rt-tr-group {
    border-color: var(--light-gray-bg) !important;
  }

  .-pagination {
    input, select {
      background: #fff !important;
      border-color: rgba(25, 25, 25, 0.24) !important;
      color: #191919 !important;
    }
  }

  .pagination-bottom {
    .-pagination {
      //-webkit-box-shadow: 0px 1px 6px 0px #d4d4d4;
      box-shadow: none !important;
      border-top: 0;

      .-btn {
        border: 1px solid rgba(25, 25, 25, 0.24);
        background: #fff;
        color: #191919;
      }
    }
  }

  .-loading {
    &.-active {
      background: rgba(242, 246, 255, 0.7);
    }
  }
}

