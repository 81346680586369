.ReactTable {
  font-size: 10px;
  color: #131722;
  font-weight: 400;
  border: none!important;

  .-pagination input {
    //background: var(--light-gray-bg);
    //color: var(--text-wite-color);
  }

  .rt-tr {
    //height: 26px;
  }

  .rt-td {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  .rt-thead {
    border-bottom: 1px solid var(--light-gray-bg) !important;
    .rt-resizable-header-content {
      white-space: initial!important;
    }
  }
  .rt-thead.-filters {
    padding: 2px 0;
    input {
      padding: 2px 6px!important;
    }
  }
  .rt-resizable-header-content {
    padding: 0 10px;
    text-align: center;
    line-height: 2;
  }

  .rt-th.-sort-asc {
    position: relative;
    box-shadow: none !important;


  }

  .rt-th.-sort-desc {
    position: relative;
    box-shadow: none !important;


  }

  .rt-th {
    font-weight: 600;
  }

  .rt-tr-group {
    border-color: var(--light-gray-bg) !important;
  }

  .-pagination input {
    background: var(--light-gray-bg)!important;
  }

  .pagination-bottom {
    .-pagination {
      border-top: 0;
      .-btn {
        background: var(--light-gray-bg);
        color: var(--text-wite-color);
      }
    }
  }
  .rt-tr-group {
    border-bottom: 1px solid rgba(0,0,0,0.05)!important;
  }
  .rt-noData {
    padding: 0!important;
  }
}